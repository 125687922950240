const skills = [
	'Express',
	'Node.js',
	'Django',
	'Mongoose',
	'MongoDB',
	'Python',
	'PostreSQL',
	'Git',
	'GitHub',
	'MongoDB Atlas',
	'Postman',
	'Heroku',
	'AWS',
	'Third Party APIs',
	'Wireframes',
	'Adobe Creative Suite',
];

export default skills;
