import React from 'react';
import './styles/Superhero.css';

function Superhero() {
	return (
		<svg
			id='_1cf4a4b6-30d3-491e-a53d-552d6204f4f6'
			data-name='1cf4a4b6-30d3-491e-a53d-552d6204f4f6'
			xxmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 896.95 746.84'>
			<g id='back-cape'>
				<path
					id='_83eae6ee-46c5-4585-a2a2-a187faac01c1'
					data-name='83eae6ee-46c5-4585-a2a2-a187faac01c1'
					className='cls-1'
					d='M744.7,495s-197.24-35.4-247.82,86L246.54,186.45s-15.17-30.34,20.23-27.82c0,0,50.57,15.17,121.38-7.59s217.47,32.87,217.47,32.87,116.32,0,288.28-73.33c0,0-78.39,75.86-22.76,121.38s5.06,111.26-60.69,136.55-70.8,75.86-65.75,123.91,0,2.53,0,2.53'
				/>
				<g id='cape-shadow'>
					<g>
						<path
							className='cls-2'
							d='M496.88,581a126.1,126.1,0,0,1,11.45-21.46L438,488.13Z'
						/>
						<path
							className='cls-2'
							d='M246.54,186.45,364.37,372.14,550.91,519.71c49.49-29.44,115.56-31.24,156.36-28.78L249.15,161.6C237.76,168.89,246.54,186.45,246.54,186.45Z'
						/>
						<path
							className='cls-2'
							d='M871.14,232c-55.63-45.52,22.76-121.38,22.76-121.38-172,73.33-288.28,73.33-288.28,73.33S459,128.28,388.15,151c-38.34,12.32-70.74,13.53-92.4,12L762.59,401.81c10.31-13.28,25.6-24.74,47.86-33.3C876.2,343.22,926.77,277.48,871.14,232Z'
						/>
						<path
							className='cls-3'
							d='M744.7,495l.16,1.47h.26c-.13-1.25-.27-2.58-.42-4-3.34-31.78-2.25-64.67,17.89-90.61L295.75,163.08c-18.34-1.26-29-4.45-29-4.45-8.47-.6-14,.68-17.62,3L707.27,490.93a355.53,355.53,0,0,1,37.43,4'
						/>
						<path
							className='cls-3'
							d='M438,488.13l70.35,71.36c11.28-17,26-29.91,42.58-39.78L364.37,372.14Z'
						/>
					</g>
					<g className='cls-4'>
						<path d='M281.38,194.32s50.57,15.17,121.38-7.59,217.45,32.88,217.45,32.88,94.1,0,238.14-53.36c11.39-30.12,37.49-55.38,37.49-55.38-172,73.33-288.28,73.33-288.28,73.33s-146.67-55.63-217.47-32.87-121.38,7.59-121.38,7.59c-35.4-2.53-20.23,27.82-20.23,27.82l10.41,16.41C261.06,197.62,267.11,193.3,281.38,194.32Z' />
						<path d='M873.11,232.25a63.41,63.41,0,0,1-7.26-6.93c-.58,14.93,4.57,29.78,19.9,42.33a79,79,0,0,1,11.18,11.06C897.42,262.49,890.39,246.39,873.11,232.25Z' />
					</g>
					<polygon
						className='cls-3'
						points='637.09 276.36 819.16 319.35 753.41 347.17 637.09 276.36'
					/>
				</g>
			</g>
			<g id='legs'>
				<g>
					<polygon
						className='cls-5'
						points='358.58 700.77 353.92 724.08 335.27 724.08 339.94 691.45 358.58 700.77'
					/>
					<polygon
						className='cls-5'
						points='237.41 700.77 242.07 724.08 260.71 724.08 256.05 691.45 237.41 700.77'
					/>
				</g>
				<path
					className='cls-13'
					d='M260.71,719.42h-23.3l-4.66,14s-23.82-.52-23.3,4.66,13.46,14,46.6,4.66l9.32-9.32S270,728.74,260.71,719.42Z'
				/>
				<path
					className='cls-13'
					d='M335.27,719.42h23.3l4.66,14s23.82-.52,23.3,4.66-13.46,14-46.6,4.66l-9.32-9.32S326,728.74,335.27,719.42Z'
				/>
				<path
					className='cls-14'
					d='M363.21,402.51H232.75c-42.46,111.85-37.28,163.12-37.28,163.12l37.28,139.81,28-4.66L232.75,561c23.3-19.16,65.25-93.21,65.25-93.21s41.93,74,65.21,93.21l-28,139.81,28,4.66,37.28-139.81S405.7,514.36,363.21,402.51Z'
				/>
			</g>
			<g id='left-arm'>
				<path
					className='cls-5'
					d='M208.88,350h-.18l-8.49-10.95-13.74,13.74,1.52,2.28-1.73.42s27.73,53.56,32.35,33.23S208.88,350,208.88,350Z'
				/>
				<path
					className='cls-6'
					d='M228.63,235.19l-37.28,37.28,14,74.57-18.64,14-14.37-43.12-18.25-54.75s-9.84-18.12,4.66-28l55.93-55.93s24-16.08,40.5-7.84-26.87,63.2-26.87,63.2'
				/>
			</g>
			<g id='body-rightarm'>
				<path
					className='cls-5'
					d='M398,66.49s-.52-23.36-16.93-36.22S377,76.46,377,76.46Z'
				/>
				<path
					className='cls-6'
					d='M228.08,234.73l4.66,167.78s74,28,130.49,0l-4.66-157.94-1.19-5.22.86-2.57,56-46.44c15.35-8.45,7.24-27.41,7.24-27.41L398.08,62.46,378.21,74.64l7,75.54-39.88,33.08-1,0v.25L242.1,178.81'
				/>
			</g>
			<g id='head'>
				<rect
					className='cls-5'
					x='279.61'
					y='155.76'
					width='37.28'
					height='41.94'
					rx='15.83'
				/>
				<circle
					className='cls-5'
					cx='293.59'
					cy='132.46'
					r='41.94'
				/>
			</g>
			<path
				id='hair'
				className='cls-7'
				d='M261.93,108.29a21.55,21.55,0,0,1-7.8-.44,17.15,17.15,0,0,1-6.6-4.32c-5.48-5.26-9.56-12.28-10.09-19.86s3-15.61,9.57-19.37c9.2-5.24,20.93-1.1,29.8,4.68,7.63,5,16.74,11.41,24.94,7.44,2.69-1.3,4.77-3.59,7.22-5.3,9.47-6.65,23.57-3.37,31.3,5.24s9.9,21.07,8.57,32.57-5.79,22.37-9.79,33.21a16.24,16.24,0,0,1-3.13,5.83c-4.92,5.05-14.13,1.13-17.37-5.13-5.63-10.89,0-29.77-13.3-36.12C294.71,101.68,273.29,107.56,261.93,108.29Z'
			/>
			<g id='cape-collar'>
				<path
					className='cls-1'
					d='M298.8,194v7.59S243.45,191.48,242,176.3l36.53,2.53Z'
				/>
				<path
					className='cls-1'
					d='M298.8,194v7.59s55.35-10.11,56.76-25.29L319,178.83Z'
				/>
				<path
					className='cls-1'
					d='M242.21,176.67s0-15.89,10.6-15.89S274,192.57,274,192.57Z'
				/>
			</g>
			<circle
				id='spec_3'
				data-name='spec 3'
				className='cls-8'
				cx='11.56'
				cy='293.66'
				r='6.94'
			/>
			<circle
				id='spec_2'
				data-name='spec 2'
				className='cls-8'
				cx='98.27'
				cy='456.68'
				r='6.94'
			/>
			<circle
				id='spec_1'
				data-name='spec 1'
				className='cls-8'
				cx='837.05'
				cy='19.65'
				r='6.94'
			/>
			<path
				id='star_2'
				data-name='star 2'
				className='cls-9'
				d='M502.8,24.23a4.24,4.24,0,0,1-2.37-5.13,1.86,1.86,0,0,0,.09-.47h0a2.13,2.13,0,0,0-3.83-1.41h0a1.76,1.76,0,0,0-.24.42A4.25,4.25,0,0,1,491.33,20a1.86,1.86,0,0,0-.47-.09h0a2.13,2.13,0,0,0-1.41,3.83h0a1.76,1.76,0,0,0,.42.24,4.26,4.26,0,0,1,2.34,5.12,1.86,1.86,0,0,0-.09.47h0A2.13,2.13,0,0,0,496,31h0a1.76,1.76,0,0,0,.24-.42,4.24,4.24,0,0,1,5.13-2.37,1.86,1.86,0,0,0,.47.09h0a2.13,2.13,0,0,0,1.42-3.82h0A2.06,2.06,0,0,0,502.8,24.23Z'
			/>
			<path
				id='star_1'
				data-name='star 1'
				className='cls-9'
				d='M843.86,446.22a4.24,4.24,0,0,1-2.37-5.13,1.86,1.86,0,0,0,.09-.47h0a2.13,2.13,0,0,0-3.83-1.41h0a1.76,1.76,0,0,0-.24.42,4.24,4.24,0,0,1-5.13,2.37,1.86,1.86,0,0,0-.47-.09h0a2.13,2.13,0,0,0-1.41,3.83h0a1.76,1.76,0,0,0,.42.24,4.24,4.24,0,0,1,2.37,5.13,1.86,1.86,0,0,0-.09.47h0A2.13,2.13,0,0,0,837,453h0a1.76,1.76,0,0,0,.24-.42,4.24,4.24,0,0,1,5.13-2.37,1.86,1.86,0,0,0,.47.09h0a2.13,2.13,0,0,0,1.41-3.83h0A1.76,1.76,0,0,0,843.86,446.22Z'
			/>
			<circle
				id='circle_3'
				data-name='circle 3'
				className='cls-10'
				cx='587.32'
				cy='643.97'
				r='6.94'
			/>
			<circle
				id='circle_2'
				data-name='circle 2'
				className='cls-10'
				cx='11.56'
				cy='595.42'
				r='6.94'
			/>
			<circle
				id='circle_1'
				data-name='circle 1'
				className='cls-10'
				cx='566.51'
				cy='92.49'
				r='6.94'
			/>
			<g
				id='cross_1'
				data-name='cross 1'
				className='cls-11'>
				<rect
					className='cls-12'
					x='8.09'
					width='3.47'
					height='19.65'
				/>
				<rect
					className='cls-12'
					y='8.09'
					width='19.65'
					height='3.47'
				/>
			</g>
			<g
				id='cross_2'
				data-name='cross 2'
				className='cls-11'>
				<rect
					className='cls-12'
					x='71.68'
					y='188.45'
					width='3.47'
					height='19.65'
				/>
				<rect
					className='cls-12'
					x='63.59'
					y='196.54'
					width='19.65'
					height='3.47'
				/>
			</g>
			<g
				id='cross_3'
				data-name='cross 3'
				className='cls-11'>
				<rect
					className='cls-12'
					x='707.56'
					y='104.05'
					width='3.47'
					height='19.65'
				/>
				<rect
					className='cls-12'
					x='699.48'
					y='112.14'
					width='19.65'
					height='3.47'
				/>
			</g>
		</svg>
	);
}

export default Superhero;
