import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './pages/App/App';
import 'bootstrap/dist/css/bootstrap.css';
import {
	BrowserRouter as Router,
	Route,
} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
	<Router>
		<React.StrictMode>
			<Route
				render={({ history }) => <App history={history} />}
			/>
		</React.StrictMode>
	</Router>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
